<template>
  <div h-scroll v-bind:key="refreshKey">
    <slot name="table-top-actions" v-bind:selectedIds="selectedIds"></slot>
    <!--    <b-button @click="createExcelFile" class="d-inline-block" size="sm" variant="outline-success">-->
    <!--      Export this page to excel-->
    <!--    </b-button>-->

    <b-table ref="clientTable" v-handy-scroll responsive v-bind:items="rows" v-bind:fields="fields" no-local-sorting
             v-bind:sort-by.sync="sort.field" v-bind:sort-desc.sync="sortIsDesc"
             striped @row-clicked="onRowClicked" hover v-bind:busy="loading"
             @sort-changed="onSortChanged"
             style="white-space: nowrap; padding-bottom: 30px"
    >
      <template #head(selection)="data">
        <div class="no-table-click">
          <b-form-checkbox v-bind:indeterminate="isIndeterminate(selected)" v-model="selected.all"
                           style="margin-top: 7px"
                           @input="val=>toggleAllRow(val)" inline
          ></b-form-checkbox>
        </div>
      </template>

      <!--Cell status-->
      <template #cell(statusId)="data" class="p-0">
        <div class="no-table-click" style="white-space: nowrap; min-width: 200px">
          <v-select class="small"
                    v-bind:value="rows[data.index].statusId"
                    v-bind:options="statusOptions"
                    :reduce="s => s.key"
                    label="name"
                    :clearable="false"
                    :appendToBody="true"
                    @input="status => saveStatus(data.item.id, status)"
          >
            <template #selected-option="option">
              <div :style="{ color: option.color }">
                {{ option.name }}
              </div>
            </template>
            <template #option="option">
              <div :style="{ color: option.color }">
                {{ option.name }}
              </div>
            </template>
          </v-select>
        </div>
      </template>


      <template #cell(representativeUserId)="data">
        <user-picker v-bind:value="rows[data.index].representativeUserId"
                     small appendToBody
                     :style="{minWidth: '200px'}"
                     :clientId="data.item.id"
                     :user-type="1"
                     :disabled="!hasPermission(permissions.CLIENT_UPDATE_REPRESENTATIVE)"
                     @update="saveClientUser"
        />
      </template>


      <template #cell(clientCell)="data">
        <ClientCell :client="data.item"/>
      </template>
      <template #cell(ftdOwnerId)="data">
        {{ getUserFullName(data.value) }}
      </template>
      <template #cell(ftdTime)="data">
        <span class="text-center" v-html="formatWithLineBreaks(data.value)"></span>
      </template>
      <template #cell(stdOwnerId)="data">
        {{ getUserFullName(data.value) }}
      </template>
      <template #cell(stdTime)="data">
        <span class="text-center" v-html="formatWithLineBreaks(data.value)"></span>
      </template>
      <template #cell(lastNote)="data">
        <div v-if="data.value != null && data.value !==''"
             style="max-width: 250px;overflow: hidden; text-overflow: ellipsis"
        >
          <user-avatar v-bind:user-id="data.value.userId" size="30"/>
          <span v-bind:id="`${data.value.id}`"
                :title="data.value.text != null && data.value.text.length > 10 ? data.value.text: null"
          >
            {{ data.value.text }}
          </span>
        </div>
      </template>

      <template #cell(orgId)="data">
        {{ getOrganizationNameById(data.value) }}
      </template>
      <template #cell(affiliateId)="data">
        {{ getAffiliateNameById(data.value) }}
      </template>

      <template #cell(country)="data">
        {{ data.value }}
      </template>
      <template #cell(ftdAmount)="data">
        <span style="font-weight: bold">{{ convertToEURO(data.value) }}</span>
      </template>

      <template #cell(countryTime)="data">
        <client-time-zone ref="timezoneWidget" v-bind:country="data.item.country"/>
      </template>

      <template #cell(businessUnitId)="data">
        {{ getBusinessUnitNameById(data.value) }}
      </template>
      <template #cell(selection)="data">
        <b-form-checkbox v-model="selectedIds[data.item.id]" inline @input="val=>toggleRow(data.item, val)"
        ></b-form-checkbox>
      </template>
      <template #cell(monetary.totalDeposits)="data">
        {{ data.value | orgCurrency(data.item.orgId) }}
      </template>
      <template #cell(monetary.totalWithdrawals)="data">
        {{ data.value | orgCurrency(data.item.orgId) }}
      </template>
      <template #cell(hasAccounts)="data">
        {{ data.value ? $t('operations.yes') : $t('operations.no') }}
      </template>
    </b-table>
    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size" @change="refresh">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
          <b-select-option v-bind:value="250">250</b-select-option>
        </b-select>
        /{{ pagination.total }} {{ $t('columns.counterNames.clients') }}

      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';
import UserAvatar from '@/components/widget/UserAvatar';
import ClientCell from '@/components/partial/clients/ClientCell';
import ClientTimeZone from '@/components/widget/utils/clientTimeZone';
import { vue } from '@/main';
import UserPicker from '@/components/widget/picker/userPicker.vue';
import mixinPermissions from '@/components/mixin/mixinPermissions';

const tdClassMap = {
  'statusId': ['px-1', 'no-click'],
  'presaleUserId': ['px-1', 'no-click'],
  'representativeUserId': ['px-1', 'no-click'],
  'retentionUserId': ['px-1', 'no-click']
};

export default {
  name: 'ClientsTable',
  components: {
    UserPicker,
    ClientTimeZone,
    UserAvatar,
    ClientCell
  },
  mixins: [mixinBase, mixinTable, mixinNotifications, mixinPermissions],
  data: () => ({
    rows: [],
    selected: { all: false },
    exportingInProgress: false,
  }),
  props: {
    filters: {
      default: () => ({})
    },
    columns: {
      default: () => ([])
    },
    hasSelection: {
      default: () => false
    },
    busy: {
      default: () => false
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.refresh();
      }
    }
  },
  created() {
    console.log('created');
    this.setPagination(1, 0, 25);
    this.refresh();
  },
  mounted() {

    // setInterval(this.updateTimes, 1000); // Update times every second

  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitNameById', 'getOrganizationNameById', 'getUserFullName', 'getAffiliateNameById']),
    ...mapGetters('clients', ['allStatuses']),

    statusOptions() {
      return this.allStatuses.map(status => {
        return {
          key: status.id,
          name: status.status,
          color: status.color
        };
      });

    },
    fields() {
      let $this = this;

      let fields = (this.columns || []).map(column => {
        let columnName = column.key.includes('.') ? column.key.substring(column.key.lastIndexOf('.') + 1) : column.key;
        return {
          key: column.key,
          label: $this.$t(`columns.${columnName}`),
          sortable: true,
          tdClass: tdClassMap[column.key],
          active: column.enable,
        };
      });
      fields.push({
        key: 'countryTime',
        label: 'countryTime',
        class: 'text-center',
        sortable: false,
        active: true,
      });
      fields = [...fields]
          .sort((a, b) => {
            if (a.key === 'id') return -1; // Move 'type' field to the second position
            if (b.key === 'id') return 1;
            return 0;
          });
      if (this.hasSelection) {
        fields.unshift({
          key: 'selection',
          label: '',
          sortable: false,
          active: true,
        });
      }
      return fields.filter(f => f.active === true);
    },
    selectedIds() {
      this.refreshKey; // Little hack to recompile
      // console.log("selectedIds()",this.selected)
      return Object.keys(this.selected)
          .filter(k => k !== 'all')
          .reduce((ans, id) => Object.assign(ans, { [id]: true }), {});
    },
    userTypeByField() {
      return {
        1: 'Representative',
        2: 'Retention',
        3: 'Presale',
      };
    },
  },
  methods: {
    ...mapActions('clients', ['getAllClients', 'exportClientDataExcel', 'updateClientStatus', 'updateClientUsers']),

    async refresh() {
      let $this = this;

      if (this.loading || this.busy) {
        return;
      }

      let loadingToken = this.setLoading();

      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }
      const result = await this.getAllClients(
          {
            pagination: this.pagination,
            sort,
            filter: this.filters,
          }
      ).catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.clients') }), $this.getErrorDescription(err));
            $this.setLoaded(loadingToken);
          });

      this.rows = result.data;
      this.setMetaDataFromResult(result);
      this.setLoaded(loadingToken);
    },
    saveStatus(id, status) {
      let $this = this;
      let loadingToken = this.setLoading();
      this.updateClientStatus({
        clientId: id,
        statusId: status
      })
          .then(client => {
            $this.updateClient(client);
            this.showSuccessNotification('Status updated successfully');
          })
          .catch(err => {
            this.showErrorNotification('Failed to update status', this.getErrorDescription(err));
          })
          .finally(() => {
            this.setLoaded(loadingToken);
          });
    },
    saveClientUser({
      clientId,
      userId,
      userType
    }) {
      let $this = this;
      let loadingToken = this.setLoading();
      console.log('changeClientUser', clientId, userId, userType);
      this.updateClientUsers({
        clientId: clientId,
        userId: userId,
        userType: userType
      })
          .then(client => {
            $this.updateClient(client);
            this.showSuccessNotification(this.userTypeByField[userType] + ' updated successfully');
          })
          .catch(err => {
            this.showErrorNotification('Failed to update ' + this.userTypeByField[userType], this.getErrorDescription(err));
          })
          .finally(() => {
            this.setLoaded(loadingToken);
          });
    },
    updateClient(client) {
      let clientIndex = this.rows.findIndex(c => c.id === client.id);
      this.rows[clientIndex] = client;
    },
    getAllSelected() {
      return this.selected;
    },
    createExcelFile() {
      let $this = this;
      if ($this.exportingInProgress) {
        this.showWarningNotification('Exporting data to Excel is already in progress. Please wait...');
        return;
      }
      this.showInfoNotification('Exporting data to Excel...');
      this.exportingInProgress = true;
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }
      this.exportClientDataExcel({
        pagination: this.pagination,
        sort,
        filter: this.filters,
      })
          .finally(() => {
            $this.exportingInProgress = false;
          });
    },
  }
};
</script>

<style scoped>

</style>
